/* App.css */
.App {
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fffdfa;
  color: #333333;
}

.timer {
  font-size: 24px;
  margin-bottom: 30px;
}

.question {
  font-size: 18px;
  margin-bottom: 10px;
  padding: 0;
  width: min(80vw, 640px);
  max-width: min(80vw, 640px);
  border: #333333 solid 5px;
}

.answer {
  text-align: center;
  width: 300px; /* 入力フォームの幅を変更 */
  height: 40px; /* 入力フォームの高さを変更 */
  padding: 5px; /* 入力フォーム内の余白を調整 */
  font-size: 18px; /* フォントサイズを変更 */
}

/* Add the following styles */
.start-button {
  font-size: 24px;
  padding: 10px 20px;
  margin: 20px;
  cursor: pointer;
}

.result-time {
  font-size: 24px;
}
h1 {
  font-size: 36px;
  margin: 20px;
}

.question img {
  object-fit: contain;
  width: min(80vw, 640px);
  max-width: min(80vw, 640px);
}

.spacer {
  height: 30px;
}

p {
  font-size: 18px;
}

body {
  margin: 0;
  padding: 0;
}
